import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/resume-details.module.css'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

const ResumeDetails = ({data}) => {
    const { html } = data.markdownRemark
    const { title, featuredImg } = data.markdownRemark.frontmatter
    return (
        <Layout>
            <div className={styles.details}>
            <h2> {title} </h2>
            <br />
                <div className={styles.featured}>
                    <Img fluid={featuredImg.childImageSharp.fluid} />
                </div>

                <div className={styles.html} dangerouslySetInnerHTML={{__html: html }} />
            </div>
        </Layout>

    )
}

export const query = graphql`
    query ResumeDetails($slug: String) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
            html
                frontmatter {
                    stack
                    title
                        featuredImg {
                        childImageSharp {
                            fluid {
                               ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `           

export default ResumeDetails
